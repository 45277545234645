<template>
  <div class="btn-group">
    <img
      v-if="imagen != null"
      class="dropdown-image"
      v-bind:src="require('@/assets/img/' + imagen + '')"
    />
    <input
      type="text"
      @click.stop="toggleMenu()"
      @blur="toggleMenuLater()"
      class="dropdown-toggle"
      v-bind:placeholder="placeholder"
      @keyup="filtrarOpciones"
      ref="dropdownInput"
      v-show="conInput"
      v-bind:style="imagen != null ? { 'padding-left': '4.4vw' } : ''"
      autocomplete="no"
      :disabled="isDisabled == true"
    />
    <input
      type="button"
      style="text-align: left"
      @click.stop="toggleMenu()"
      @blur="toggleMenuLater()"
      class="dropdown-toggle"
      v-bind:placeholder="placeholder"
      @keyup="filtrarOpciones"
      ref="dropdownInput2"
      v-show="!conInput"
      :value="placeholder"
      v-bind:style="imagen != null ? { 'padding-left': '4.4vw' } : ''"
      autocomplete="no"
      :disabled="isDisabled == true"
    />
    <span class="caret"></span>
    <img class="dropdown-caret" src="../assets/img/flechas/v_bottom.png" />
    <!-- <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOption[this.name] === undefined">
      <span class="caret"></span>
    </li>-->

    <ul
      class="dropdown-menu"
      v-bind:class="{ 'dropdown-menu-up': paraArriba }"
      v-show="showMenu"
    >
      <li v-for="option in opcionesFiltradas" :key="option.id" :id="option.id">
        <a href="javascript:void(0)" @click="updateOption(option)">{{
          option[getName]
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: {
        name: "",
      },
      showMenu: false,
      opcionesFiltradas: null,
      placeholderText: "Please select an item",
    };
  },
  props: {
    options: {
      type: [Array, Object],
    },
    selected: {},
    placeholder: [String],
    usarSelected: [Boolean],
    paraArriba: [Boolean],
    name: [String],
    conInput: [Boolean],
    imagen: [String],
    isDisabled: [Boolean],
  },
  computed: {
    getName() {
      if (this.name != null) return this.name;
      else return "name";
    },
  },
  mounted() {
    // this.selectedOption = this.selected;
    if (this.usarSelected == true) {
      this.selectedOption = this.selected;
      if (this.conInput) {
        this.$refs.dropdownInput.value = this.selectedOption[this.getName];
      } else {
        this.$refs.dropdownInput2.value = this.selectedOption[this.getName];
      }
    }
    this.opcionesFiltradas = this.options;
    if (this.placeholder) {
      this.placeholderText = this.placeholder;
    }
  },
  created() {
    this.$eventHub.$on("dropdown-cerrar", (that) => this.cerrarOtros(that));
  },
  methods: {
    cerrarOtros(that) {
      if (!(this == that)) this.showMenu = false;
    },
    updateOption(option) {
      this.selectedOption = option;
      // this.showMenu = false;
      if (this.conInput) {
        this.$refs.dropdownInput.value = this.selectedOption[this.getName];
      } else {
        this.$refs.dropdownInput2.value = this.selectedOption[this.getName];
      }
      this.$emit("updateOption", this.selectedOption);
    },

    toggleMenu() {
      // this.$eventHub.$emit("dropdown-cerrar", this);
      this.showMenu = !this.showMenu;
      this.opcionesFiltradas = this.options;
    },
    toggleMenuLater() {
      const that = this;
      setTimeout(function () {
        // that.$eventHub.$emit("dropdown-cerrar", this);
        that.showMenu = false;
        that.opcionesFiltradas = this.options;
      }, 150);
    },
    filtrarOpciones(el) {
      this.opcionesFiltradas = [];
      const that = this;
      this.options.forEach(function (item) {
        if (
          item[that.getName]
            .toUpperCase()
            .indexOf(el.target.value.toUpperCase()) > -1
        )
          that.opcionesFiltradas.push(item);
      });
    },
    resetDropwdown() {
      this.selectedOption = {};
      this.$refs.dropdownInput.value = "";
      this.$refs.dropdownInput2.value = "";
      this.$emit("updateOption", this.selectedOption);
    },
  },
};
</script>

<style>
.btn-group {
  position: relative;
}
.btn-group a:hover {
  text-decoration: none;
}
.dropdown-menu-up {
  top: unset !important;
  bottom: 4vw !important;
}
.btn-group:focus-within > .dropdown-menu {
  display: block;
  opacity: 1;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 17vw;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  opacity: 0;
  pointer-events: auto;
}
.dropdown-menu > li > a {
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #333333;
  white-space: nowrap;
  text-decoration: none;
}
.dropdown-menu > li > a:hover {
  background: #efefef;
  color: #409fcb;
}
.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}
.caret {
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 0.45vw dashed;
  border-top: 4px solid\9;
  border-right: 0.3vw solid transparent;
  border-left: 0.3vw solid transparent;
  position: absolute;
  right: 1.2vw;
  top: 50%;
  color: #000000;
  transform: translateY(-50%);
  display: none;
}
.dropdown-caret {
  position: absolute;
  right: 1vw;
  top: 50%;
  transform: translateY(-50%);
  width: 0.8vw;
  pointer-events: none;
}
.dropdown-image {
  position: absolute;
  width: 1.65vw;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5vw;
}
li {
  list-style: none;
}
</style>
